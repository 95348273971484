.telstra-header {
  width: 100%;
  z-index: 100;
  margin: 0;
  height: 40px;
  background-color: #fff;
}
.telstra-header::before {
  top: 0;
  left: 0;
  height: 0.125rem;
  width: 100%;
  background: #462ff9;
  content: "";
  position: absolute;
  z-index: 3;
  display: block;
}
.telstra-header::before {
  height: 2px;
  background: #462ff9;
}
.telstra-header-container #t-logo {
  height: 35px;
  width: 35px;
  margin-top: 15px;
  margin-left: 5px;
}
.sign-out {
  float: right !important;
  margin-right: 16px;
  color: #0064d2 !important;
}
.icon-sign-out {
  width: 22px;
  height: 22px;
  margin-right: 5px;
  stroke: #0064d2 !important;
}
@media only screen and (min-width: 48rem) {
  .telstra-header {
    height: 60px;
  }
  .telstra-header-container #t-logo {
    height: 35px;
    width: 120px;
    margin-top: 30px;
    margin-left: 0;
  }
  .sign-out {
    margin-right: 100px;
    margin-top: 10px;
  }
}
