.esim-header {
  height: auto;
}
.esim-microsoft-container {
  padding-top: 2%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
}
.newcustomer-section {
  padding-top: 5%;
}
.subtext-section-one {
  padding-top: 2%;
}
.subtext-section-two {
  margin-top: 0% !important;
}
.subtext-help-section-one {
  padding-top: 0.5%;
}
@media only screen and (max-width: 310px) {
  .subtext-newc-section-two,
  .subtext-help-section-three {
    width: 90%;
  }
}
@media only screen and (min-width: 310px) and (max-width: 370px) {
  .subtext-newc-section-two,
  .subtext-help-section-three {
    width: 92%;
  }
}
@media only screen and (min-width: 370px) {
  .subtext-help-section-three {
    width: 80%;
  }
  .subtext-newc-section-two {
    width: 90%;
  }
}
