.Paypal-form {
  padding-top: 20px;
  width: 100%;
}
.Paypal-body {
  width: 100%;
  margin-top: 23px;
  margin-bottom: 40px;
}
.Paypal-footer {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  display: inline-block;
}
.paypal-footer-icon {
  padding-bottom: 10px;
  margin-right: 7px;
}
.footer-icon-span {
  float: left;
}
.footer-text {
  padding-left: 10%;
}
@media only screen and (max-width: 767px) {
  .Paypal-form {
    width: 100%;
  }
  .Paypal-body {
    width: 100%;
    margin-top: 23px;
    margin-bottom: 40px;
  }
}
