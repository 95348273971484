.able-autosuggest .able-autosuggest-input {
  position: relative;
}
.able-autosuggest .able-autosuggest-input__img,
.able-autosuggest .able-autosuggest-input__spinner {
  position: absolute;
  bottom: 24px;
  right: 10px;
}
.able-autosuggest .able-autosuggest-input__spinner {
  bottom: 31px;
}
.able-autosuggest .able-autosuggest-input .autosuggest_spinner {
  padding-top: 15px;
}
