/* The container */
.radio-tab {
  display: block;
  position: relative;
  margin-bottom: 12px;
  border: 1px solid #D2D2D2;
  border-radius: 3px;
  padding: 20px 20px;
  padding-left: 50px;
  cursor: pointer;
  font-size: 16px;
  font-family: Telstra Text Variable, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radio-tab.active {
  border: 2px solid #0064D2;
  background-color: white;
}
.radio-tab.radio-tab-focus {
  box-shadow: 0 0 0 3px #0064D2 !important;
  background-color: #F0F6FD;
}
/* Hide the browser's default radio button */
.radio-tab input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  left: 15px;
  height: 18px;
  width: 18px;
  background-color: #757575;
  border-radius: 50%;
}
/* When the radio button is checked, add a blue background */
.radio-tab input:checked ~ .checkmark,
.radio-tab.active input ~ .checkmark {
  background-color: #0064D2;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
}
/* Show the indicator (dot/circle) when checked */
.radio-tab input:checked ~ .checkmark:after,
.radio-tab.active input ~ .checkmark::after {
  display: block;
  top: 6px;
  left: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}
/* Style the indicator (dot/circle) */
.radio-tab .checkmark:after {
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: white;
}
.radio-tab:hover {
  background-color: #F0F6FD !important;
  outline: 1px solid #0064D2 !important;
}
