.contact-next-button {
  margin-left: 25px !important;
}
.esim-wrapper .contact-details-fieldset {
  margin-bottom: 0px;
}
.contact-details-sole-trader {
  top: -15px;
  margin-top: 45px;
}
.contact-details-image {
  margin-top: 20%;
  width: 80%;
  margin-left: 12rem;
}
.existing-tdi-message {
  margin-bottom: 20px;
  font-size: 12px;
}
.contact-details-visitor-message {
  margin-top: -10px !important;
}
.contact-details-close-svg {
  float: right !important;
}
@media only screen and (max-width: 420px) {
  .contact-details-close-svg {
    margin-right: 0px;
  }
}
.contact-details-tick-svg {
  padding-right: 11px;
}
.contact-details-fieldset tr td {
  padding-right: 25px !important;
}
.contact-details-fieldset tr th {
  padding-left: 25px !important;
}
.contact-details-fieldset table {
  margin-bottom: 0px;
  border: 1px solid #BDBDBD;
  margin-bottom: 20px;
}
@media only screen and (max-width: 420px) {
  .contact-details-fieldset table {
    border: none !important;
  }
  .contact-details-fieldset table tr td {
    padding-right: 0px !important;
  }
  .contact-details-fieldset table tr th {
    padding-left: 0px !important;
  }
}
.contact-details-fieldset table .full-name td,
.contact-details-fieldset table .full-name th {
  padding-top: 20px !important;
}
.contact-details-fieldset table .customer-type td,
.contact-details-fieldset table .customer-type th {
  padding-bottom: 20px !important;
}
.customerCheckDone input {
  border: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.customerCheckDone .search-icon {
  display: none !important;
}
.customerCheckDone .form-element {
  min-height: 65px !important;
}
.customerCheckDone #ABN-description {
  display: none !important;
}
.customerCheckDone input[name="BusinessName"] + label {
  margin-top: -30px !important;
}
.id-page .alert-bar {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-left: 4px solid #0064D2;
  margin-bottom: 12px;
  padding: 0rem !important;
  margin-top: 30px;
  width: 360px;
  height: 64px;
}
@media only screen and (max-width: 420px) {
  .id-page .alert-bar {
    width: 312px;
  }
}
.id-page .alert-card {
  padding: 9px 25px 15px 45px;
}
.id-page .custom-icon {
  margin-top: -10px;
}
.id-page .sole-trader-hide {
  display: none;
}
.id-page > div.infobanner-contactdetails {
  padding-bottom: 0px !important;
}
.id-page > div.infobanner-idv {
  padding-bottom: 0px !important;
}
.id-page > div {
  padding-bottom: 70px;
}
#status-live-region {
  position: absolute;
  left: -9999px;
}
.send-code {
  margin-left: 1.5rem;
  margin-top: 24px;
  margin-bottom: 32px;
  margin-right: 2rem;
  width: 86%;
}
@media only screen and (max-width: 420px) {
  .send-code {
    width: 82%;
    margin-left: 30px;
    margin-top: 2%;
  }
}
.send-code-info {
  margin-top: 9px;
  width: 80%;
}
.skip-button {
  margin-left: 8rem;
}
.update-inline {
  text-decoration: underline;
  margin-top: 0px !important;
  margin-bottom: 40px;
}
.confirm-identity-radio-grp {
  width: 60%;
  margin-left: 32px;
}
.radio-header {
  margin-top: 32px;
  margin-left: 32px;
}
@media only screen and (min-width: 62rem) {
  .send-code {
    margin-right: 0px !important;
    margin-left: 2.5rem !important;
  }
  .skip-button {
    margin-left: 10rem !important;
  }
}
.otp-div {
  width: 86%;
  margin-left: 7%;
  margin-top: 5%;
}
@media only screen and (max-width: 767px) {
  .otp-div {
    margin-left: 10%;
    width: 80%;
  }
}
.otp-div input {
  width: 100% !important;
}
.resend-code {
  margin-top: -5%;
  margin-bottom: 5%;
  float: left;
  margin-left: 40%;
}
@media only screen and (max-width: 767px) {
  .resend-code {
    margin-left: 35%;
  }
}
.send-otc {
  margin-left: 35px;
  margin-top: 32px;
  margin-bottom: 56px;
  margin-right: 2rem;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .send-otc {
    margin-left: 30px;
    width: 94%;
  }
}
.send-otc button {
  width: 86%;
}
.otc-verify-msg {
  margin-left: 32px;
  margin-right: 32px;
}
.full-modal-error-content {
  margin-bottom: 10%;
}
.picto-something-went-wrong {
  margin-top: 5%;
  margin-left: 5%;
}
.error-modal-text {
  width: 90%;
}
#newNumber,
#otcInput {
  width: 100%;
}
.update-screen-header {
  height: 45px !important;
}
.update-screen-header .modal-close-btn {
  margin-right: -30px;
}
@media only screen and (max-width: 767px) {
  .update-modal-content {
    overflow-y: auto;
    max-height: 515px;
    overflow-x: hidden;
  }
}
@media screen and (max-width: 642px) and (min-width: 640px) {
  .update-modal-content {
    height: 300px !important;
  }
  .update-modal-content .otp-div {
    margin-left: 6%;
  }
}
@media screen and (max-width: 570px) and (min-width: 421px) {
  .update-modal-content {
    width: 75% !important;
    margin-left: 10% !important;
  }
  .update-modal-content .otp-div {
    margin-left: 7%;
  }
}
.update-content-heading {
  margin-top: 5%;
}
@media only screen and (max-width: 767px) {
  .update-content-heading {
    margin-top: 10%;
  }
}
@media only screen and (max-width: 420px) {
  .update-content-heading {
    margin-top: 15%;
  }
}
.contact-details-form .validation-error {
  font: 400 0.875rem/1.42857143 Telstra Text Variable, Helvetica Neue, Helvetica, Arial, sans-serif !important;
  letter-spacing: 0.01125rem !important;
  color: #d0021b;
}
@media screen and (max-width: 104rem) {
  .contact-details-page .business-subheading {
    font-size: 20px;
  }
}
.update-limit-reach-full-error .fullpage-error-container {
  width: auto;
  max-width: 580px;
}
@media screen and (min-width: 48rem) {
  .update-limit-reach-full-error .fullpage-error-container .SpinningButton-container button {
    width: auto;
  }
}
