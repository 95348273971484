.ppa-input {
  width: 320px;
  background-color: #fff;
  border: 1px solid #cdcdcd;
  padding: 10px 34px 10px 20px;
}
.ppa-select {
  width: 320px;
  background: url(../../../assets/img/telstra/forms/select-arrow-2x.png) 100% 50% no-repeat #fff;
  background-size: 30px 15px;
  font-size: 14px!important;
  line-height: 18px!important;
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #555;
  border: 1px solid #cdcdcd;
  padding: 10px 20px;
  border-radius: 0;
  min-width: 230px;
}
.form-group {
  min-height: 110px !important;
  margin-bottom: 0px !important;
}
.form-group .btn-input-control-service {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px 20px;
}
@media only screen and (max-width: 420px) {
  .form-group .btn-input-control-service {
    width: 100%;
  }
}
@media only screen and (max-width: 420px) {
  .form-group .selectedDevice-Telstra-Prepaid-Wireless-Broadband {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 420px) {
  .form-group .portingNumber-btn-input {
    margin-top: 15px;
  }
}
.form-group .form-element {
  min-height: 92px !important;
}
.form-group.sim-details-radio-grp {
  margin-top: 15px;
}
.form-group.sim-details-radio-grp label:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.form-group #serviceDetails-mobile-desc [class*=able-MessageSection] {
  max-width: 100%;
}
.btn.primary {
  float: right !important;
  margin-top: 20px !important;
}
@media only screen and (max-width: 420px) {
  .btn.primary {
    float: unset !important;
    width: 86%;
  }
}
.device-Telstra-Prepaid-Wireless-Broadband,
.portNumber {
  margin-left: 10px !important;
}
@media only screen and (max-width: 420px) {
  .device-Telstra-Prepaid-Wireless-Broadband,
  .portNumber {
    margin-left: 0px !important;
    margin-top: -15px;
  }
}
@media only screen and (max-width: 420px) {
  .device-Prepaid-Mobile-3G-Handset,
  .providedNumber {
    margin-bottom: -15px;
  }
}
@media only screen and (min-width: 62rem) {
  .sim-kit-image-mobile {
    display: none;
  }
  .div-image {
    display: block;
  }
  .sim-image {
    margin-top: 60px;
  }
  .device-label {
    margin-top: 24px !important;
  }
  .sim-check-form {
    margin-top: 20px;
    margin-bottom: 80px;
  }
  .sim-check-form:has(#auth-container) {
    margin-top: 60px;
  }
  .esim {
    margin-top: 60px;
  }
}
.sim-kit-image-mobile {
  margin-bottom: 24px;
}
.ppa-bottom {
  margin-bottom: 1.5rem;
}
#Telstra-Prepaid-Wireless-Broadband {
  margin-left: 10px !important;
}
.yourPhoneNumber {
  height: 32px;
  width: 170px;
  color: #333333;
  font-size: 28px;
  line-height: 32px;
}
.bold {
  font-weight: bold !important;
}
#sim-details {
  list-style-type: none;
  padding: 0;
  margin-top: 40px;
}
@media only screen and (max-width: 420px) {
  #sim-details {
    padding-top: 10px;
  }
}
#sim-details li {
  float: left;
  margin: 0 5px 0 0;
  width: 100px;
  height: 40px;
  position: relative;
}
#sim-details .device-label {
  margin-bottom: 0.5rem;
}
input#simNumber {
  outline: 0;
  border-width: 0 0 2px;
}
.form-element .has-error {
  border-top-width: 0px !important;
  border-right-width: 0px !important;
  border-left-width: 0px !important;
}
input#simNumber {
  outline: 0;
  border-width: 0 0 2px;
}
.mt-sheet__toolbar:before {
  background: linear-gradient(to right, #a0148c, #ffad6e 30%, #ff7aa3) !important;
}
.isNumberChecked input {
  border: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
.isNumberChecked #simNumber-description {
  display: none;
}
.isNumberChecked .number-tick {
  display: none;
}
.isNumberChecked .sim-details {
  margin-top: 0px !important;
}
#hide-cid {
  display: none;
}
.breakWord {
  word-break: break-word !important;
}
.notice-card {
  margin-bottom: 25px;
  margin-top: 25px;
  border-left: 4px solid #0064d2;
  border-radius: 4px;
}
.notice-card::after {
  background: none !important;
}
