#learn-more-link {
  color: #0062DA;
  text-decoration: underline;
}
.learn-more-modal header [class$=__picto] > svg {
  margin-left: 0;
  width: 2rem;
  height: 2rem;
}
.learn-more-modal header [class$=__picto] > svg use,
.learn-more-modal header [class$=__picto] > svg path,
.learn-more-modal header [class$=__picto] > svg rect {
  fill: #0064d2;
}
@media screen and (min-width: 48rem) {
  .learn-more-modal footer {
    display: flex;
  }
}
@media screen and (max-width: 47.99rem) {
  .learn-more-modal .able-Modal-Content--Comfortable__content {
    top: 5rem;
    height: auto;
    border-radius: 0.5rem;
    width: 90%;
    margin-left: 5%;
  }
}
