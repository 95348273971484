.ppa-progress {
  margin-top: 32px;
  padding-right: 1%;
}
@media only screen and (max-width: 420px) {
  .ppa-progress {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
.step-name {
  height: 20px;
  color: #414141;
  font-family: Telstra Text Variable, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
}
.step {
  width: 26.67%;
}
.hide {
  display: none;
}
.step-wrapp {
  display: flex !important;
}
.ppa-progress .ppa-steps {
  position: relative;
  top: 4px;
  overflow: hidden;
  margin-left: 14px;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid var(--accentPrimary);
  border-radius: 50%;
  list-style: none;
  text-indent: 14px;
}
.step-wrapper {
  width: 23%;
  display: -ms-flexbox;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.step-number-selected {
  border: 1px solid #D8D8D8;
  color: #fff;
}
.step-number {
  border-radius: 50%;
  border: 2px solid #D2D2D2;
  width: 34.5px;
  height: 34.5px;
  font-family: Telstra Text Variable, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  padding: 4px;
  text-align: center;
  font-weight: bold;
  letter-spacing: -0.1px;
  margin-left: 3px;
  background-color: #FFFFFF;
}
svg.tick use,
svg.tick path {
  fill: var(--accentPrimary) !important;
}
.ppa-progress .progress-info {
  min-height: 20px;
  display: flex;
  width: 100%;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
}
.horizontal-line {
  border-top: 2px solid #D8D8D8;
  margin-top: 15px;
  width: 100%;
  flex: 1 1;
  margin-left: 3px;
}
.dummy-horizontal-line {
  border-top: 0px solid #D8D8D8;
  margin-top: 15px;
  width: 19%;
  margin-left: 3px;
}
.titleMargin-0 {
  margin-left: -16px;
}
.titleMargin-1 {
  margin-left: -25px;
}
.titleMargin-2 {
  margin-left: -10px;
}
.titleMargin-3 {
  margin-left: -30px;
}
.titleMargin-4 {
  margin-left: -9px;
}
.titleMargin-5 {
  margin-left: -2px;
}
.titleMargin-6 {
  margin-left: -2px;
}
@media only screen and (max-width: 420px) {
  .titleMargin-6 {
    margin-left: -10px;
  }
}
@media only screen and (min-width: 62rem) {
  .ppa-progress {
    padding-left: 6%;
    margin-bottom: 30px;
    margin-left: 30px;
  }
  .step-name {
    display: block;
    margin-top: 4%;
  }
  .step-number-selected {
    color: #fff;
  }
  .step-number {
    border-radius: 50%;
    border: 2px solid #D2D2D2;
    width: 34.5px;
    height: 34.5px;
    font-family: Telstra Text Variable, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 16px;
    padding: 4px;
    text-align: center;
    font-weight: bold;
    letter-spacing: -0.1px;
    margin-left: 3px;
    background-color: #FFFFFF;
  }
  .visited {
    border: 0px;
  }
  .titleMargin-0 {
    margin-left: -18px;
  }
  .titleMargin-1 {
    margin-left: -25px;
  }
  .titleMargin-2 {
    margin-left: -10px;
  }
  .titleMargin-3 {
    margin-left: -23px;
  }
  .titleMargin-4 {
    margin-left: -9px;
  }
  .titleMargin-5 {
    margin-left: -32px;
  }
  .titleMargin-5_1 {
    margin-left: -2px;
  }
  .titleMargin-6 {
    margin-left: -32px;
  }
}
.visited {
  border: 0px;
  padding: 0;
  margin-left: 0;
}
.current {
  background-color: var(--accentPrimary);
  color: #FFFFFF;
  border-color: var(--accentPrimary);
}
