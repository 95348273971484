.go-back-btn-nav {
  float: left;
  margin-left: 40px;
  margin-top: 14px;
}
@media only screen and (max-width: 420px) {
  .go-back-btn-nav {
    margin-left: 10px !important;
  }
}
