#globalMessageSection.infobanner-recharge.row,
#globalMessageSection.infobanner-payments.row {
  margin-left: 0;
  margin-right: 0;
}
#globalMessageSection.infobanner-recharge.row .col-lg-5,
#globalMessageSection.infobanner-payments.row .col-lg-5 {
  padding: 0;
}
#globalMessageSection .messageSection {
  max-width: 100%;
}
#globalMessageSection.infobanner-review,
#globalMessageSection.infobanner-recharge,
#globalMessageSection.infobanner-payments,
#globalMessageSection.infobanner-success {
  margin-bottom: 20px;
}
#globalMessageSection.infobanner-contactdetails {
  margin-bottom: 40px;
}
#globalMessageSection.infobanner-portingflow {
  margin-top: 70px;
}
