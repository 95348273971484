.review-details .offer-card-details {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 0rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.review-details .small-font {
  font-size: 14px;
  padding-left: 1.3rem;
  margin-right: 1rem;
}
.review-details .button {
  width: 100% !important;
}
.review-details .custom-tick {
  fill: transparent;
  padding-right: 0.5rem;
}
.review-details .md {
  display: none;
}
.review-details .header {
  margin-bottom: 32px;
}
/*--------Desktop --------------*/
@media only screen and (min-width: 62rem) {
  .review-details .offer-card-details {
    margin-left: 150px !important;
  }
  .review-details .sm {
    display: none;
  }
  .review-details .alert-card {
    width: 100%;
  }
  .review-details .md {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .review-details .md {
    display: none;
  }
}
