.telstra-footer {
  flex-shrink: 0;
  width: 100%;
  background-color: #F4F4F4;
  box-sizing: border-box;
  position: fixed;
  bottom: 0px;
  height: 64px;
  z-index: 100;
}
.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 24px 60px;
}
.footer-first-three a:first-child {
  padding-left: 0;
}
.footer-container a {
  color: #414141;
  font-size: 14px;
  letter-spacing: 0.18px;
  line-height: 17px;
  text-decoration: none;
  display: inline-block;
  padding: 14px 14px;
}
.foot-height {
  height: 44px !important;
}
.footer-container [tabindex]:focus:not(:hover),
.footer-container a:focus:not(:hover) {
  box-shadow: 0 0 0 3px #0099f8;
  background: #cadff7 !important;
  text-decoration: underline !important;
}
.footer-container a:visited {
  text-decoration: underline !important;
  outline: none !important;
}
.footer-anchor-hover:hover {
  text-decoration: underline !important;
  outline: none;
  background: #cadff7 !important;
}
@media only screen and (max-width: 900px) {
  .telstra-footer {
    position: inherit;
    height: 274px;
    padding-top: 10px !important;
  }
  .footer-container {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    padding: 0 15px;
  }
  .footer-first-three {
    display: flex;
    flex-direction: column;
  }
  .footer-last-two {
    display: flex;
    flex-direction: column;
  }
  .footer-container a {
    padding-left: 0;
    padding-top: 22px;
  }
  .footer-container-three a:first-child {
    padding-top: 0 !important;
  }
}
