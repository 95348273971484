.auto-recharge-details {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #D2D2D2;
  border-radius: 3px;
  margin-bottom: 56px;
  padding: 24px;
  padding-top: 4px;
}
.auto-recharge-details .cal-image {
  width: 27px;
  height: 27px;
  float: left;
  margin-right: 14px;
  margin-top: 22px;
}
@media only screen and (min-width: 62rem) {
  .auto-recharge-details {
    width: 80%;
  }
  .auto-recharge-details .cal-image {
    width: 32px;
    height: 32px;
    margin-right: 24px;
    margin-top: 22px;
  }
}
