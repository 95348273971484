.ppa-custom-table {
  border-radius: 4px;
  border: 1px solid #d2d2d2;
}
.ppa-custom-table table {
  table-layout: fixed;
  width: 100%;
}
.ppa-custom-table table thead {
  background: #f4f4f4;
}
.ppa-custom-table table th,
.ppa-custom-table table td {
  padding: 12px;
}
.ppa-custom-table table tr:not(:last-of-type),
.ppa-custom-table table thead tr {
  border-bottom: 1px solid #d2d2d2;
}
.ppa-price-rise-table thead {
  background: #e2eeff !important;
}
.ppa-price-rise-table th,
.ppa-price-rise-table td {
  border: 1px solid !important;
}
